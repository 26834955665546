<template>
    <div class="JTopicList">

        <div v-if="isLogin && deviceList && deviceList.length > 0" style="position:relative;height:auto;margin-top:10px;">
            <table class="configItemTalbe" width="99%">
                <thead>
					<tr><td>{{'ID'|i18n}}</td><td>{{'Topic'|i18n}}</td>
					<td>{{'status'|i18n}}</td><td>{{'actId'|i18n}}</td>
					<td>{{'clientId'|i18n}}</td><td>{{'createdTime'|i18n}}</td>
                    <td>{{"Operation"|i18n}}</td></tr>
                </thead>
                <tr v-for="c in deviceList" :key="'ide' + c.id">
				  <td>{{c.id}}</td>
				  <td>{{c.topic}}</td>
				  <td>{{c.status?"启用":"禁用"}}</td>
				  <td>{{c.createdBy}}</td>  <td>{{c.clientId}}</td>
				  <td>{{c.updatedTime | formatDate(2)}}</td>
                    <td>
                        <a  @click="viewDev(c)">{{"View"|i18n}}</a>&nbsp;
                        <a  @click="updateDev(c)">{{"Update"|i18n}}</a>&nbsp;
						<a  @click="deleteDev(c.id)">{{"Delete"|i18n}}</a>
                    </td>
                </tr>
            </table>
        </div>

        <div v-if="isLogin && deviceList && deviceList.length > 0" style="position:relative;text-align:center;">
            <Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
                  show-elevator show-sizer show-total @on-change="curPageChange"
                  @on-page-size-change="pageSizeChange" :page-size-opts="[10, 30, 60,100]"></Page>
        </div>

		<div v-if="!isLogin">
            No permission!
        </div>
		
        <div v-if="!deviceList || deviceList.length == 0">
            No data!
        </div>
		
	<Drawer ref="deviceInfoDrawer"  v-model="deviceDrawer.drawerStatus" :closable="false" placement="right" :transfer="true"
	         :styles="deviceDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="80" :mask-closable="true" :mask="true">
		<el-row>
			<el-button size="mini" @click="deviceDrawer.drawerStatus = false">取消</el-button>
			<el-button :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">保存</el-button>
		</el-row>
		
		<el-row>
			<el-col :span="24">{{"主题名称(完整主题为 /_a/m/"+(this.$jr.auth.actInfo.id)+"/"+(dev.topic?dev.topic:"")+")" }}</el-col>
			<el-col><el-input v-model="dev.topic" :disabled="model==3" /></el-col>
		</el-row>
		<el-row>
			<el-col :span="6">{{"描述"}}</el-col>
			<el-col><el-input v-model="dev.desc" :disabled="model==3" /></el-col>
		</el-row>
		
		<el-row>
			<el-col>状态</el-col>
			<el-select style="width:100%" v-model="dev.status" placeholder="请选择">
				<el-option :key="1" :value="1" label="启用"></el-option>
				<el-option :key="0" :value="0" label="禁用"></el-option>
			</el-select>
		</el-row>
		
		<el-row>
			<el-col :span="6">{{"createdTime"|i18n}}</el-col>
			<el-col><el-input :value="dev.createdTime | formatDate(2)" disabled /></el-col>
		</el-row>
		
		<el-row>
			<el-col :span="6">{{"ID"|i18n}}</el-col>
			<el-col><el-input v-model="dev.id" disabled /></el-col>
		</el-row>
		
		<el-row>
			<el-col :span="6">{{"所属账号"|i18n}}</el-col>
			<el-col><el-input v-model="dev.createdBy" disabled /></el-col>
		</el-row>
		
		<el-row>
			<el-col :span="6">{{"所属租户"|i18n}}</el-col>
			<el-col><el-input v-model="dev.clientId" disabled/></el-col>
		</el-row>
		
	</Drawer>
	
    </div>
</template>

<script>
 
const cid = 'JTopicList'
 
export default {
        name: cid,
        components: {},
        data() {
            return {
                errorMsg:'',
                isLogin:false,
                deviceList: [],
				
				queryParams:{size:10,curPage:1,ps:{}},
				totalNum:0,

				model:3,
                dev : {},
				
                deviceDrawer: {
                    drawerStatus : false,
                    drawerBtnStyle : {left:'0px',zindex:1000},
                },

            }
        },

        methods: {
			
			doQuery() {
				this.queryParams.curPage = 1
			    this.refresh()
			},
			
			viewDev(c){
				this.selProductId = c.productId
				this.model = 3
				this.dev = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},
			
			addDev() {
				this.selProductId = ""
				this.model = 2
				this.dev = {}
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},
			
			updateDev(c){
				this.selProductId = c.productId
				this.model = 1
				this.dev = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},

			doAddOrUpdateParam() {
				if (!this.checkParam(this.dev)) {
					return
				}
			
				this.dev.productId = parseInt(this.selProductId)
				
				console.log(this.dev)
				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(-1120984588, [this.dev])
						.then((resp) => {
							if (resp.code == 0) {
								this.deviceDrawer.drawerStatus = false;
								this.$notify.info({title: '提示',message:"更新成功"});
							} else {
								this.$notify.error({title: '错误',message: resp.msg || "未知错误"});
							}
						}).catch((err) => {
							this.$notify.error({title: '错误',message: err});
						});
				} else if(this.model == 2) {
					//add
					this.$jr.rpc.invokeByCode(751015026, [this.dev])
						.then((resp) => {
							if (resp.code == 0 && resp.data) {
								this.deviceDrawer.drawerStatus = false
								this.refresh()
								this.$notify.info({title: '提示',message:"保存存成功"});
							} else {
								this.$notify.error({
									title: '错误',
									message: resp.msg || "未知错误"
								});
							}
						}).catch((err) => {
							this.$notify.error({
								title: '错误',
								message: err
							});
						});
				}
			},
			
			checkParam(d) {
				if(!d.topic || d.topic.length==0) {
					this.$notify.error({
						title: '错误',
						message: "主题不能为空"
					});
					return false
				}
				return true
			},
			
			deleteDev(defId){
				//delFunDef
				this.$jr.rpc.invokeByCode(823901890, [defId])
					.then((resp)=>{
						if(resp.code == 0){
						   this.refresh()
						} else {
						   this.$notify.error({
								title: '错误',
								message: resp.msg
							});
						}
					}).catch((err)=>{
						this.$notify.error({
							title: '错误',
							message: resp.msg
						});
				});
			},

            curPageChange(curPage){
				this.queryParams.curPage = curPage
                this.refresh();
            },

            pageSizeChange(pageSize){
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
                this.refresh();
            },

            refresh() {
                let self = this;
                this.isLogin = this.$jr.auth.isLogin();
                if(this.isLogin) {
                    let params = this.getQueryConditions();
					
                    let self = this;
					//deviceFunDefs
                    this.$jr.rpc.invokeByCode(-164508892, [params])
                        .then((resp)=>{
                            if(resp.code == 0){
                                self.deviceList = resp.data;
								self.deviceList.forEach(e=>{
									if(!e.productId) {
										e.productId = ""
									}else {
										e.productId = e.productId + ""
									}
								})
								console.log( self.deviceList)
                                self.totalNum = resp.total;
                                //self.queryParams.curPage = 1;
                            } else {
								this.$notify.error({
									title: '错误',
									message: resp.msg
								});
                            }
                        }).catch((err)=>{
						   this.$notify.error({
								title: '错误',
								message: JSON.stringify(err)
							});
                    });
                }else {
                    self.deviceList = [];
                }
            },

            getQueryConditions() {
                return this.queryParams;
            },
        },

        async mounted () {
            this.$jr.auth.addActListener(this.refresh);
            this.refresh();
            let self = this;
            this.$bus.$emit("editorOpen",
                {"editorId":cid, "menus":[
                    {name:"REFRESH",label:"Refresh",icon:"ios-cog",call:self.refresh},
					{name:"Add",label:"Add",icon:"ios-cog",call:self.addDev},
					]
                });

            let ec = function() {
                this.$jr.auth.removeActListener(cid);
                this.$off('editorClosed',ec);
            }

            this.$bus.$on('editorClosed',ec);
        },

        beforeDestroy() {
            this.$jr.auth.removeActListener(cid);
        },

    }
</script>

<style>
    .JTopicList{
        min-height: 500px;
    }

	.descCol{
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}
	
	.title {
		font-weight: bold;
	}
	
	
</style>